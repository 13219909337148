import React from "react"
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper kf4fe997fko-editor_css",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Collaborative Team Hiring</p>
          </span>
        ),
        className: "banner5-title",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>Strengthen your relationships with Hiring Managers</p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: "content",
        className: "banner5-content",
        children: (
          <span>
            <span>
              <p>Give time back to busy managers.&nbsp;</p>
            </span>
          </span>
        ),
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
          className: "banner5-button",
          type: "primary",
          children: (
            <span>
              <p>Start Now</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: "banner5-image",
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/client-portal.gif",
  },
}
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Share, Make Decisions, and Improve</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>Share Candidates</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Share all candidate information - interview responses,
                    documents, scores - and let managers review on their own
                    time.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>Make Confident Decisions</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>
                      Let managers review, decide, and leave feedback from one
                      platform.&nbsp;
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Improve Communciation</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Gain analytics on decision making time, common next steps,
                    and popular candidates so you can keep improving your
                    process.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/undraw_Redesign_feedback_re_jvm0.svg",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: {
    className: "content1-title",
    children: (
      <span>
        <p>Give time back to busy managers.</p>
      </span>
    ),
  },
  content: {
    className: "content1-content",
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <p>
                  With Hiring Portals, busy managers free up hours in their day
                  by evaluating candidate interviews on their own time and
                  engaging only the best candidates for face-to-face
                  interviews.&nbsp;
                </p>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
}
export const Feature20DataSource = {
  wrapper: { className: "home-page-wrapper content2-wrapper" },
  OverPack: { className: "home-page content2", playScale: 0.3 },
  imgWrapper: { className: "content2-img", md: 10, xs: 24 },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/undraw_Detailed_information_re_qmuc.svg",
    className: "kevljgxpqyq-editor_css",
  },
  textWrapper: { className: "content2-text", md: 14, xs: 24 },
  title: {
    className: "content2-title",
    children: (
      <span>
        <p>Evaluate people, not paper.&nbsp;</p>
      </span>
    ),
  },
  content: {
    className: "content2-content",
    children: (
      <span>
        <span>
          <p>
            Adding texture to a paper resume is extremely valuable to hiring
            staff. Having to review hundreds of resumes takes valuable time away
            from the most important part of the hiring process: the
            people.&nbsp;
          </p>
        </span>
      </span>
    ),
  },
}
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Get the feedback when you need it.&nbsp;</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: (
          <span>
            <span>
              <p>
                Our all in one Hiring Portal makes it easy to get feedback and
                make decisions.&nbsp;
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>Real Time Notifications</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Get notified when candidates are viewed, comments are left,
                  and ratings are made.&nbsp;
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Use Your Brand</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            All credit stays with you and your team. Easily
                            white label the entire Hiring Portal to match your
                            brand.&nbsp;
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <p>Customizable Next Steps</p>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Want to schedule the next interview? Sign an offer letter?
                  Fine tune the platform for your process.&nbsp;
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block3",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>One Click Platform</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Your team doesn't need special software or hardware. Just
                      send a link, and enable confident decision making.{" "}
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block4",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>Engage with Managers</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Always know what your managers are thinking about
                            candidates, ensuring you get to the finish line as
                            fast as possible.
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block5",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Simple to Share Candidates</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Share single candidates, or create a shortlist to quickly get
                  feedback from managers.&nbsp;
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image",
      },
      {
        name: "title",
        children: (
          <span>
            <p>Improve your relationships with Managers</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <span>
              <span>
                <p>
                  Use DeepHire to make sure your managers see you as a trusted
                  advisor
                </p>
              </span>
            </span>
          </span>
        ),
        className: "title-content",
      },
    ],
  },
  button: {
    className: "",
    children: {
      a: {
        className: "button",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <p>Start Now</p>
          </span>
        ),
      },
    },
  },
}
